
import { Component, Vue, Watch } from 'vue-property-decorator';
import { GeneralSettings } from '@/modules/settings/model'
import Input from '@/components/ui/input/Input.vue';
import Select from '@/components/ui/select/Select.vue';
import Divider from '@/modules/settings/components/divider/Divider.vue';
import SettingsButton from '@/modules/settings/components/settings-button/SettingsButton.vue';
import GeneralModule from '@/store/modules/general/GeneralModule';
import List from '@/modules/settings/components/list/List.vue';
import PopupDialogDefault from '@/components/ui/popup-dialog/PopupDialogDefault.vue';
import ListItem from '@/modules/settings/components/list/list-item/ListItem.vue';
import HugeSwitch, { HugeSwitchOption } from '@/components/ui/huge-switch';
import ContextMenuToggle from '@/components/context-menu/items/ContextMenuToggle.vue';
import ApiClient from '@/api/api.client';
import Notify from '@/services/helpers/notify';
import { SettingFields } from '@/models/dto/responses/settings.response';
import createField from '@/api/settingsTeams/actions/createField';
import SettingsModule from '@/store/modules/settings/SettingsModule';
import i18n, { Locale } from '@/services/i18n';



@Component({
  components: { SettingsButton, PopupDialogDefault, Divider, Input, Select, List, ListItem, HugeSwitch, ContextMenuToggle },
})
export default class Settings extends Vue {
  public userDataItems: Array<SettingFields> =[];
  public get companyTypes(): Array<HugeSwitchOption> {
    return [
      {
        title: this.$tc('Settings.CompanyProductType'),
        description: 'В системе не будет клиентов',
        subtitle: this.$tc('Settings.CompanyProductDescr'),
        value: 'product',
      },
      {
        title: this.$tc('Settings.CompanyServiceType'),
        description: 'В системе будут клиенты',
        subtitle: this.$tc('Settings.CompanyServiceValue'),
        value: 'service',
      },
    ];
  }
  public get companyServices(): Array<HugeSwitchOption> {
    return [
      {
        title: this.$tc('Settings.CompanyTypeMultiple'),
        description: 'В системе будут направления услуг',
        value: 'many',
      },
      {
        title: this.$tc('Settings.CompanyTypeSingle'),
        description: 'В системе не будет направлений услуг',
        value: 'one',
      },
    ];
  }
  public settings: GeneralSettings = {
    company_name: '',
    company_type: '',
    country: '',
    language: '',
    services_count: '',
    userFields: { isRequired: false },
  }
  public pageLoaded = false;
  public companyName = '';
  public fieldContextMenu = false;
  get localesList() {
    return GeneralModule.localesList;
  }
  get countryList() {
    return GeneralModule.countryList;
  }
  checkBoxChanged(key: number)
  {
    ApiClient.settingsTeams.setFieldStatus(key).then(() => 
    {
      Notify.success('Изменения сохранены')
    })
  }
  deleteField(id: number)
  {
    ApiClient.settingsTeams.deleteField(id).then(async () => 
    {
      this.userDataItems = await ApiClient.settingsTeams.getFields()
      Notify.success('Поле удалено')
    })
  }
  setCountry(value: string)
  {
    this.settings.country = value;
  }
  createField(value: string)
  {
    ApiClient.settingsTeams.createField(value).then( async() => 
    {
      Notify.success('Новое поле создано')
      this.userDataItems = await ApiClient.settingsTeams.getFields()
      this.fieldContextMenu = false;
    })
  }
  get userDataFilteredItems()
  {
    return this.userDataItems.sort((a,b) => {
      if(a.driver > b.driver)
      {
        return -1
      }
      if(a.driver < b.driver)
      {
          return 1;
      }
      return 0;
    })
  }
  setLanguage(value: string)
  {
    this.settings.language = value;
  }
  editField(item:any)
  {
    if(!item)
    {
      return;
    }
    else
    {
      ApiClient.settingsTeams.editField(item.id,item.value).then(async() => 
      {
        this.userDataItems = await ApiClient.settingsTeams.getFields()
        Notify.success('Изменения сохранены');
      })
    }
  }
  setCompanyTypes(value: string)
  {
    this.settings.company_type = value;
  }
  setCompanyServices(value: string)
  {
    this.settings.services_count = value;
  }
  setCompanyName(value: Event & { target: HTMLSelectElement })
  {
    this.companyName = value.target.value;
    
    ApiClient.settings.setGlobalSettings({company_name: this.companyName, settings: JSON.stringify(this.settings)}).then(() =>
    {
      Notify.success('Изменения сохранены')
    })
  }

  get sectionSettings()
  {
    return SettingsModule.getGeneralSettings;
  }
  
  async mounted() {
	if (!this.settings.language)
		this.settings.language = 'ru';

    this.userDataItems = await ApiClient.settingsTeams.getFields()
    if(!this.sectionSettings.length)
    {
      await SettingsModule.fetchGeneralSettings();
    }
    
    this.settings = {
      ...this.settings,
      ...this.sectionSettings.filter((el: any) => el.slug === 'general')[0].settings as GeneralSettings,
    }
    this.$nextTick(() => 
    {
      this.pageLoaded = true;
    })
    this.companyName = this.sectionSettings.filter((el) => el.slug === 'general')[0].company_name
    this.settings.language = this.sectionSettings.filter((el) => el.slug === 'general')[0].settings.language
    GeneralModule.setupLanguage(this.settings.language as Locale)
  }

  @Watch('settings', { deep: true })
  fetchSettings(value:any)
  { 
    if(this.pageLoaded)
      ApiClient.settings.setGeneralSettings(this.settings).then(() => Notify.success('Изменения сохранены'))
    GeneralModule.setupLanguage()
  }
}
